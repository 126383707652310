import Card from "./Card";

export default function Cards({
  setBonus,
  bonus,
  cards,
  games,
  attempts,
  setCards,
  matchCount,
  setMessage,
  setMatchCount,
  setAttempts,
  setStart,
  setBomb,
}) {
  // if (window.Telegram) {
  //   window.Telegram.WebApp.expand();
  // }

  const matchCheck = (index) => {
    if (cards[index].status === "active matched" || attempts <= 0) {
      return; // The card is already open or no attempts left
    }

    const cardId = cards[index].id;
    cards[index].status = "active matched"; // Update the card status immediately
    setCards([...cards]);

    // Update match count locally before setting it to state
    const updatedMatchCount = {
      ...matchCount,
      [cardId]: (matchCount[cardId] || 0) + 1,
    };

    // Check for winning condition before updating the state
    if (updatedMatchCount[cardId] === 3) {
      if (cardId === 5) {
        setMessage(`Ви виграли 300 бонусів, продовжуйте грати!`);
        setBonus((prev) => prev + 300);
      } else {
        setMessage(`Ви виграли 100 бонусів, продовжуйте далі!`);
        setBonus((prev) => prev + 100);
      }
      setAttempts(0); // Stop further clicks by setting attempts to 0
      setStart(false); // Optionally stop the game
    } else {
      // This block will run only if the winning condition isn't met
      const newAttempts = attempts - 1;
      setAttempts(newAttempts);
      if (newAttempts <= 0) {
        setMessage("Спробуйте знову");
        setStart(false); // Optionally stop the game
      }
      if (games === 0 && attempts === 0) {
        setMessage(
          "Гру закінчено. Приходьте завтра та заберіть більше бонусів"
        );
        setStart(false); // Optionally stop the game
      }
    }

    // Special handling for the bomb
    if (cardId === 2) {
      setAttempts(0);
      setBomb(true);
      setMessage("Уппс, ви потрапили на бомбу, почніть гру спочатку");
      setStart(false); // Stop the game immediately
    }

    // Update the match count state at the end
    setMatchCount(updatedMatchCount);
  };

  return (
    <div className="main">
      <div className="infoBlock">
        <div className="message">
          <span>Кількість Ігр:</span> <span className="color">{games}</span>
        </div>
        <div className="message">
          <span>Спроби:</span> <span className="color">{attempts}</span>
        </div>
      </div>
      <div className="container">
        {cards.map((card, index) => (
          <Card
            key={index}
            card={card}
            index={index}
            clickhandler={() => matchCheck(index)}
          />
        ))}
      </div>
    </div>
  );
}
