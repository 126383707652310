import "./App.css";
import coin from "../src/assets/coin.svg";
import Cards from "./Components/Cards";
import { useEffect, useState } from "react";
import Modal from "./Components/Modal";
import BonusThree from "./Components/BonusThree";

const initialValue = [
  { id: 10, name: "image", status: "", img: "/images/x.jpg" },
  { id: 11, name: "image", status: "", img: "/images/x.jpg" },
  { id: 2, name: "image", status: "", img: "/images/bomb.png" },
  { id: 3, name: "image", status: "", img: "/images/money.png" },
  { id: 3, name: "image", status: "", img: "/images/money.png" },
  { id: 3, name: "image", status: "", img: "/images/money.png" },
  { id: 3, name: "image", status: "", img: "/images/money.png" },
  { id: 3, name: "image", status: "", img: "/images/money.png" },
  { id: 5, name: "image", status: "", img: "/images/supergift.png" },
  { id: 5, name: "image", status: "", img: "/images/supergift.png" },
  { id: 5, name: "image", status: "", img: "/images/supergift.png" },
  { id: 6, name: "image", status: "", img: "/images/gift.png" },
  { id: 6, name: "image", status: "", img: "/images/gift.png" },
  { id: 6, name: "image", status: "", img: "/images/gift.png" },
  { id: 6, name: "image", status: "", img: "/images/gift.png" },
  { id: 6, name: "image", status: "", img: "/images/gift.png" },
].sort(() => Math.random() - 0.5);

function App() {
  // const [platform, setPlatform] = useState("");
  const [bonus, setBonus] = useState(0);
  const [games, setGames] = useState(4);
  const [attempts, setAttempts] = useState(4);
  const [message, setMessage] = useState("");
  const [matchCount, setMatchCount] = useState({});
  const [cards, setCards] = useState(initialValue);
  const [start, setStart] = useState(true);
  const [bomb, setBomb] = useState(false);
  const [showWindow, setShowWindow] = useState(false);

  // useEffect(() => {
  useEffect(() => {
    if (window.Telegram) {
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.disableVerticalSwipes();
      window.Telegram.WebApp.expand();
      window.Telegram.WebApp.isVerticalSwipesEnabled = false;
    }
  }, []);

  useEffect(() => {
    if (attempts === 0) {
      setStart(false);
    }
  }, [attempts]);

  function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const restartGame = () => {
    if (attempts === 0 && games === 0) window.Telegram.WebApp.close();
    setStart(true);
    if (games > 0) {
      setGames((prev) => prev - 1);
      setAttempts(4);
      setMatchCount({});
      setMessage("");

      // Reset each card's status and shuffle them
      const resetCards = initialValue.map((card) => ({
        ...card,
        status: "",
      }));
      const shuffledCards = shuffle(resetCards);
      setCards(shuffledCards);
    } else {
      setMessage(
        "Уппс, спроби закінчились, приходьте завтра та забирайте більше бонусів"
      );
      setStart(false);
    }
  };

  const handleChangeWindow = () => {
    setShowWindow(!showWindow);
  };
  return (
    <div className="mainContainer">
      <div className="titleContainer">
        <h1 className="title">Вгадайка</h1>
        <div>{window.Telegram.WebApp.isVerticalSwipesEnabled}</div>
        <div className="bonusBlock">
          <img className="coinImg" src={coin} alt="coin" />
          <h2 className="bonus">{bonus}</h2>
        </div>
      </div>
      {showWindow ? (
        <BonusThree />
      ) : (
        <>
          <Cards
            bonus={bonus}
            setBonus={setBonus}
            setCards={setCards}
            setMessage={setMessage}
            setMatchCount={setMatchCount}
            setAttempts={setAttempts}
            setGames={setGames}
            setStart={setStart}
            cards={cards}
            attempts={attempts}
            matchCount={matchCount}
            initialValue={initialValue}
            games={games}
            setBomb={setBomb}
          />
          {!start && (
            <Modal
              restartGame={restartGame}
              message={message}
              attempts={attempts}
              games={games}
            />
          )}
        </>
      )}
      <div className="containerChangeBonus">
        <button
          className={`changeBonus ${
            showWindow ? "changeBonusThreeWindow" : ""
          } `}
          onClick={handleChangeWindow}
        >
          {!showWindow ? `Обміняти бонуси` : `  Повернутись до гри`}
        </button>
      </div>
    </div>
  );
}

export default App;
