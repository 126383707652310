import React from "react";
import remove from "../assets/plus.svg";

const ModalCartDetail = ({ modalInfo, setModalInfo }) => {
  const description =
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.";

  const productName = "Набір косметики";

  const handleClose = () => {
    setModalInfo(modalInfo.show === false);
  };
  return (
    <div className="cartDetailContainer">
      <div className="cartDetailContent">
        <h4 className="cartDetailProductName">{productName}</h4>
        {/* <img
          src={remove}
          className="removeCartDetail"
          alt="remove"
          onClick={handleClose}
        /> */}
        <img className="cartDetailImg" src={modalInfo.src} alt="item" />
        <p>{description}</p>
        <button className="cartDetailContentButton">Обміняти</button>
        <button className="cartDetailContentBack" onClick={handleClose}>
          Повернутись назад
        </button>
      </div>
    </div>
  );
};

export default ModalCartDetail;
