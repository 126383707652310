import React from "react";
import three from "./../assets/transparent_three.png";
import redApple from "./../assets/red_apple.png";
import greenApple from "./../assets/green_apple.png";
import { useState } from "react";
import cosmetics from "../assets/cosmetics.webp";
import Modal from "./Modal";
import ModalCartDetail from "./ModalCartDetail";

// const cook = "";
// const plate = "";
// const shoes = "";
// const snake = "";
// const boots = "";
// const phone = "";
// const superprice = "";
// const cup = "";

const initialApples = [
  { id: 1, color: "red", src: redApple, items: cosmetics },
  { id: 2, color: "red", src: redApple, items: cosmetics },
  { id: 3, color: "red", src: redApple, items: cosmetics },
  { id: 4, color: "red", src: redApple, items: cosmetics },
  { id: 5, color: "green", src: greenApple, items: cosmetics },
  { id: 6, color: "green", src: greenApple, items: cosmetics },
  { id: 7, color: "green", src: greenApple, items: cosmetics },
  { id: 8, color: "green", src: greenApple, items: cosmetics },
];
const BonusThree = () => {
  const [apples, setApples] = useState(initialApples);
  const [modalInfo, setModalInfo] = useState({
    show: false,
    content: null,
    src: null,
  });

  const handleClick = (i) => {
    const apple = apples[i];
    if (apple.src === apple.items) {
      // Якщо src вже дорівнює items, показати модальне вікно
      setModalInfo({
        show: true,
        content: apple.description,
        src: apple.items,
      });
    } else {
      // Інакше, змінити зображення
      const newApples = apples.map((item, index) =>
        index === i ? { ...item, src: item.items } : item
      );
      setApples(newApples);
    }
  };

  return (
    <>
      {modalInfo.show ? (
        <ModalCartDetail modalInfo={modalInfo} setModalInfo={setModalInfo} />
      ) : (
        <div className="threeContainer">
          <div className="appleContainer">
            <img className="three" src={three} alt="three" />
            {apples.map((apple, index) => (
              <img
                key={apple.id}
                className={`apple ${apple.color}Apple${apple.id}`}
                src={apple.src}
                alt="apple"
                onClick={() => handleClick(index)}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default BonusThree;
