import React from "react";

const Card = React.memo(({ card, index, clickhandler }) => {
  const cardClassName = card.status ? "active" : "";

  // if (window.Telegram) {
  //   window.Telegram.WebApp.expand();
  // }

  return (
    <div
      className={`card ${cardClassName}`}
      onClick={() => clickhandler(index)}
    >
      <img className="cartImage" src={card.img} alt={card.name} />
    </div>
  );
});

export default Card;
